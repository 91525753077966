const user_scopes = {
    descriptions: {
        custom_data: '사용자 지정 데이터',
        email: '이메일 주소',
        phone: '전화번호',
        profile: '이름, 사용자 이름, 아바타 및 기타 프로필 정보',
        roles: '사용자 역할',
        identities: '연결된 소셜 정체성',
        'urn:logto:scope:organizations': '조직 정보',
        'urn:logto:scope:organization_roles': '조직 역할',
        address: '주소',
    },
};
export default Object.freeze(user_scopes);
